import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FaFilePdf, FaRegFilePdf } from "react-icons/fa";
import { BsQrCode, BsQrCodeScan } from "react-icons/bs";
import { TiArrowLeft, TiArrowRight, TiEye } from "react-icons/ti";
import {
  MdDelete,
  MdMerge,
  MdOutlineCopyright,
  MdCampaign,
  MdCancel,
} from "react-icons/md";
import { IoCloseCircleSharp, IoEye } from "react-icons/io5";
import { FaCircleH, FaRegCircleCheck } from "react-icons/fa6";
import { AiTwotoneMail } from "react-icons/ai";
import { CgExport } from "react-icons/cg";
import "./style.css";
import { API, Postup_APIS } from "api/API";
import Axios from "api/Axios";
import Loading from "./Loading";
import { GrStatusGood } from "react-icons/gr";
import { IoMdDoneAll } from "react-icons/io";
import { FcIdea } from "react-icons/fc";

const ReusableMenu = ({
  options,
  ITEM_HEIGHT,
  handleOption,
  height,
  YourSelection,
  APICall,
  data,
  setMenuChecks,
  after_ICON,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(YourSelection, 'YourSelection')
  // const handleClick =(event)=>{
  //   setAnchorEl(event.currentTarget)

  // }

  const handleClick = async (event) => {
    if (APICall === "yes") {
      setAnchorEl(event.currentTarget);
      try {
        setIsLoading(true);
        const result = await Axios.post(
          API.API_Checks_For_Menu_Items,
          data.state
        );
        if (result.status === 200) {
          setIsLoading(false);
          // console.log(result.data);
          setMenuChecks(result.data.finalStatus);
        }
      } catch (error) {
        console.log(error, "Error while getting API Response");
        setIsLoading(false);
      }
    }
    if (APICall === "postup") {
      setAnchorEl(event.currentTarget);
      try {
        setIsLoading(true);
        const result = await Axios.post(
          Postup_APIS.Postup_Menu_Checks,
          data.state
        );
        if (result.status === 200) {
          setIsLoading(false);
          setMenuChecks(result.data);
        }
      } catch (error) {
        console.log(error, "Error while getting API Response");
        setIsLoading(false);
      }
    }
    if (APICall === "no") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (option) => {
    setAnchorEl(null);
  };

  return (
    <div className={after_ICON}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "chips",
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // width: "22ch",
            overflowY: "auto", // Add overflow property
            height: height || "unset", // Set a fixed height
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            // disabled={option === YourSelection}
            disabled={YourSelection?.includes(option)}
            onClick={() => {
              handleOption(option);
              handleClose();
            }}
          >
            {option === "Upload Assets" && (
              <FaFilePdf style={{ marginRight: 8 }} />
            )}
            {option === "Add QR" && <BsQrCode style={{ marginRight: 8 }} />}
            {option === "View QR Assets" && (
              <TiEye style={{ marginRight: 8 }} />
            )}
            {option === "Merge Printables" && (
              <MdMerge style={{ marginRight: 8 }} />
            )}
            {option === "Delete PO" && (
              <MdDelete style={{ marginRight: 8 }} color="#c62828" />
            )}
            {option === "View RWO (PO)" || option === "View Comps" || option === "View Hanger" || option === "View Rolled" ? (
              <IoEye style={{ marginRight: 8 }} color="#15223f" />
            ):null}
            {option === "Upload Comps" && (
              <MdOutlineCopyright color="#c62828" style={{ marginRight: 8 }} />
            )}
            {option === "Upload Hanger" && (
              <FaCircleH color="#15223f" style={{ marginRight: 8 }} />
            )}
            {option === "Export Merged RWO" && (
              <CgExport color="#15223f" style={{ marginRight: 8 }} />
            )}
            {option === "Merge Comps" && <MdMerge style={{ marginRight: 8 }} />}
            {option === "Merge RWO" || option === "Merge Rolled" ? ( <MdMerge style={{ marginRight: 8 }} />): null}
            {option === "Merge Hanger" && (
              <MdMerge style={{ marginRight: 8 }} />
            )}
            {option === "Export QR Codes" && (
              <BsQrCodeScan style={{ marginRight: 8 }} />
            )}
            {option === "Final Approve" && (
              <FaRegCircleCheck style={{ marginRight: 8 }} />
            )}
            {option === "Email List Upload" && (
              <AiTwotoneMail style={{ marginRight: 8 }} />
            )}
            {option === "Send Campaign" && (
              <MdCampaign style={{ marginRight: 8 }} />
            )}
            {option === "Email Preview" || option === "View Email Records" || option === 'View Email Status' ? (
              <IoEye style={{ marginRight: 8 }} />
            ):""}
            {option === "Export Merged Comps" && (
              <CgExport color="#15223f" style={{ marginRight: 8 }} />
            )}
            {option === "Export Merged Hanger" || option === "Export Merged Rolled" ? (
              <CgExport color="#15223f" style={{ marginRight: 8 }} />
            ):null}
             {option === "Upload PDF" && (
              <FaRegFilePdf color="#15223f" style={{ marginRight: 8 }} />
            )}
             {option === "SmartSheet Approve" && (
              <GrStatusGood color="#15223f" style={{ marginRight: 8 }} />
            )}
            {option === "Disapprove" || option === 'Reject'? (
              <IoCloseCircleSharp style={{ marginRight: 8, color:'#c62828' }} />
            ):null}
             {option === "Approve" && (
              <FaRegCircleCheck style={{ marginRight: 8 }} />
            )}
            {option === "Done" && (
              <IoMdDoneAll style={{ marginRight: 8, fontSize:16 }} />
            )}
            {option === "Another Suggestion" && (
              <TiArrowRight style={{ marginRight: 8, fontSize:16 }} />
            )}
            {option === "Previous Suggestion" && (
              <TiArrowLeft style={{ marginRight: 8, fontSize:16 }} />
            )}
            {option === "Cancel" && (
              <MdCancel style={{ color:'red', marginRight: 8, fontSize:16 }} />
            )}

            {option}
          </MenuItem>
        ))}
      </Menu>
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </div>
  );
};

export default ReusableMenu;
