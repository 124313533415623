// Libraries
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import { Navigate } from "react-router";
// Pages
import MainLayout from "layout/MainLayout";
import Template1 from "views/pages/template Management/templates/Template1";
import ViewTempByID from "views/pages/template Management/ViewTempByID";
import GeneratePDF from "views/pages/Pdf-Management/generatePDF/GeneratePDF";
import Merge from "views/pages/Pdf-Management/MergePDF/Merge";
import AddMissingContent from "views/pages/content Management/AddMissingContent";
import EditUser from "views/pages/User Management/Users/EditUser";
import Profile from "layout/MainLayout/Header/ProfileSection/Profile";
import InviteUser from "views/pages/User Management/InviteNewUser/InviteUser";
import Import_Pdf from "views/pages/Pdf-Management/Import_PDF/Import_Pdf";
import All_Manual_PDF from "views/pages/Pdf-Management/AllManualPDF/All_Manual_PDF";
import ViewAIpdf from "views/pages/Pdf-Management/AllManualPDF/ViewAIpdf";
import CreateWallboard from "views/pages/Wallboard Management/CreateWallboard/CreateWallboard";
import Template2 from "views/pages/template Management/templates/Template2";
import ViewTemplate2 from "views/pages/template Management/templates/template2/ViewTemplate2";
import View_wallboard from "views/pages/Wallboard Management/View_Wallboard/View_wallboard";
import ShowVersionsData from "views/pages/Wallboard Management/View_Wallboard/AI_Containers.js/ShowVersionsData";
import View_PDF from "views/pages/Pdf-Management/generatePDF/View_PDF";
import View_PDF_Screen from "views/pages/Wallboard Management/View_Wallboard/View_PDF_Screen";
import Template3 from "views/pages/template Management/templates/Template3";
import Cookies from "js-cookie";
import NotFound from "./NotFound";
import ICYMI_2 from "views/pages/template Management/templates/template2/2-ICYMI_Poll";
import ICYMI_Cartoon from "views/pages/template Management/templates/template2/2-ICYMI_Cartoon";
import I_2AD_ICYMI from "views/pages/template Management/templates/template2/I_2AD_ICYMI";
import ICYMI_1_Cartoon from "views/pages/template Management/templates/template2/1-ICYMI_Cartoon";
import ICYMI_II from "views/pages/Wallboard Management/View_Wallboard/AI_Containers.js/ICYMI-II/ICYMI_II";
import Preprint from "views/pages/PrePrint/Preprint";
import PreprintListings from "views/pages/PrePrint/Listings/PrePrintListings";
import Media from "views/pages/Media/Media";
import ViewAssets from "views/pages/PrePrint/Upload Assets/ViewAssets";
import Hanger_Comp_Data from "views/pages/PrePrint/Hanger_Comp_Data/Hanger_Comp_Data";
import Perks from "views/pages/Perks/Perks";
import Postup from "views/pages/Postup/Postup";
import Preview_Email_List from "views/pages/Postup/Listings/Preview_Email_List";
import ViewRSSFeed from "views/pages/Postup/Listings/ViewRSSFeed";
import DataCollection from "views/pages/Data Collection/DataCollection";
import Data_collectionRWO from "views/pages/Data Collection/View RWO/Data_collectionRWO";
import Metadata from "views/pages/meta-data/Metadata";
import ICYMI_CARTOON_NO_COL1_QR from "views/pages/Wallboard Management/View_Wallboard/AI_Containers.js/1ICYMI-Cartoon/ICYMI_CARTOON_NO_COL1_QR";

// Pages with Lazy Loading
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const SelectWallboard = Loadable(
  lazy(() => import("views/pages/Wallboard Management/selectWallboard"))
);
const CreateTemplate = Loadable(
  lazy(() => import("views/pages/Requirement Gathering/CreateTemplate"))
);
const ViewTemplate = Loadable(
  lazy(() => import("views/pages/Requirement Gathering/ViewTemplate"))
);
const SelectTemplates = Loadable(
  lazy(() => import("views/pages/template Management/SelectTemplates"))
);
const ValidateEligibleContent = Loadable(
  lazy(() => import("views/pages/content Management/ValidateEligibleContent"))
);
const ViewTemplates = Loadable(
  lazy(() => import("views/pages/template Management/ViewTemplates"))
);

const Users = Loadable(
  lazy(() => import("views/pages/User Management/Users/Users"))
);

// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoute = ({ element }) => {
  // Check if the user is authenticated (you can use your authentication logic here)
  const isAuthenticated = Cookies.get("userToken") !== undefined;
  return isAuthenticated ? (
    element
  ) : (
    // Redirect to the login page if the user is not authenticated
    <Navigate to="/login" replace />
  );
};

const GetItem = localStorage.getItem("Profile_Details");
const Details = JSON.parse(GetItem);

const MainRoutes = {
  path: "/",
  // element: <MainLayout />,
  element: <ProtectedRoute element={<MainLayout />} />,
  // element: Token? <MainLayout /> : <AuthLogin3 /> ,
  children: [
    {
      path: "dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "data-collection",
      children: [
        {
          path: "/data-collection",
          element: <DataCollection />,
        },
        {
          path: "listing/:id/:po",
          element: <Data_collectionRWO />,
        },
      ],
    },

    {
      path: "meta-data",
      children: [
        {
          path: "/meta-data",
          element: <Metadata />,
        }
      ],
    },

    {
      path: "selectwallboard",
      children: [
        {
          path: "/selectwallboard/pending",
          element: <SelectWallboard />,
        },
        {
          path: "/selectwallboard/approved",
          element: <SelectWallboard />,
        },
        {
          path: "/selectwallboard/rejected",
          element: <SelectWallboard />,
        },
        {
          path: "/selectwallboard/auto-tag",
          element: <SelectWallboard />,
        },
        {
          path: "/selectwallboard/used",
          element: <SelectWallboard />,
        },
        {
          path: "create-wallboard",
          element: <CreateWallboard />,
        },
        {
          path: "view-ai-wallboard/:id",
          element: <View_wallboard />,
        },
        {
          path: "view-version/:id",
          element: <ShowVersionsData />,
        },
        {
          path:'view-versions/:id',
          element: <ICYMI_CARTOON_NO_COL1_QR />
        },
        {
          path: "/selectwallboard/View-pdf/:id",
          element: <View_PDF_Screen />,
        },
        {
          path: "icymi-2/:id",
          element: <ICYMI_II />,
        },
      ],
    },

    {
      path: "template-management",
      children: [
        {
          path: "predefined-templates",
          element: <SelectTemplates />,
        },
        {
          path: "view-template",
          element: <ViewTemplates />,
        },
        {
          path: "view-template-data/:id",
          element: <ViewTempByID />,
        },
        {
          path: "template2",
          element: <Template2 />,
        },
        {
          path: "template3",
          element: <Template3 />,
        },
        {
          path: "view-template2/:id",
          element: <ViewTemplate2 />,
        },
        {
          path: "2icymi/:id",
          element: <ICYMI_2 />,
        },
        {
          path: "2icymi_cartoon/:id",
          element: <ICYMI_Cartoon />,
        },
        {
          path: "I2ADICYMI/:id",
          element: <I_2AD_ICYMI />,
        },
        {
          path: "ICYMI-1_Cartoon/:id",
          element: <ICYMI_1_Cartoon />,
        },
      ],
    },
    {
      path: "contentmanagement",
      children: [
        {
          path: "validate-eligible-content",
          element: <ValidateEligibleContent />,
        },
        {
          path: "add-missing-content/:id",
          element: <AddMissingContent />,
        },
      ],
    },

    {
      path: "media",
      element: <Media />,
    },

    {
      path: "createtemplate",
      element: <CreateTemplate />,
    },
    {
      path: "viewtemplate",
      element: <ViewTemplate />,
    },
    {
      path: "template1",
      element: <Template1 />,
    },
    {
      path: "pdf-management",
      children: [
        {
          path: "/pdf-management/pending",
          element: <GeneratePDF />,
        },
        {
          path: "/pdf-management/approved",
          element: <GeneratePDF />,
        },
        {
          path: "/pdf-management/rejected",
          element: <GeneratePDF />,
        },
        {
          path: "merge-pdf",
          element: <Merge />,
        },
        {
          path: "import-manual-pdf",
          element: <Import_Pdf />,
        },
        {
          path: "all-manual-pdf",
          element: <All_Manual_PDF />,
        },
        {
          path: "view-ai-pdf",
          element: <ViewAIpdf />,
        },
        {
          path: "view-pdf/:id",
          element: <View_PDF />,
        },
      ],
    },
    {
      path: "profile",
      element: <Profile />,
    },
    {
      path: "/*",
      element: <NotFound />,
    },
  ],
};

if (Details && Details?.role === "1") {
  MainRoutes.children.push({
    path: "user-management",
    children: [
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "edit-user/:id",
        element: <EditUser />,
      },
      {
        path: "invite-new-user",
        element: <InviteUser />,
      },
    ],
  });
}

if ((Details && Details?.role === "1") || Details?.role === "4") {
  MainRoutes.children.push(
    {
      path: "prePrint",
      element: <Preprint />,
    },
    {
      path: "prePrint/listing/:id/:po",
      element: <PreprintListings />,
    },
    {
      path: "prePrint/view-assets",
      element: <ViewAssets />,
    },
    {
      path: "prePrint/view-assets",
      element: <ViewAssets />,
    },
    {
      path: "prePrint/hanger-comp-data",
      element: <Hanger_Comp_Data />,
    },
    {
      path: "perks",
      element: <Perks />,
    },
    {
      path: "postup",
      children: [
        {
          path: "",
          element: <Postup />,
        },
        {
          path: "email-list",
          element: <Preview_Email_List />,
        },
        // {
        //   path: "rss-feed",
        //   element: <ViewRSSFeed />,
        // },
      ],
    }
  );
}

export default MainRoutes;
