import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Box,
  Typography,
  IconButton,
  Collapse,
  Pagination,
} from "@mui/material";
import axios from "axios";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { saveAs } from "file-saver";

// ICONS
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Components
import Popup from "components/Popup";
import { MENU_OPEN } from "store/actions";
import UploadAssets from "../PrePrint/Upload Assets/UploadAssets";
import { API, Auto_Tag_APIs } from "api/API";
import Axios from "api/Axios";
import ReusableMenu from "components/Menu";
import Upload_Assets from "./Upload Assets/Upload_Assets";
import Manage_Assets from "./Upload Assets/Manage_Assets";
import { FaUpload } from "react-icons/fa";
import { RiFolderSettingsFill } from "react-icons/ri";
import { replaceSymbols } from "functions/Functions";

const ListingData = ({
  setIsLoading,
  setSnackbar,
  isLoading,
  Get_All_listings,
  next,
  setShowData,
  childRowExpansion,setChildRowExpansion,rowExpansion,setRowExpansion,
  showData,
  RowClose,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState({
    uploadAsset: false,
    ManageAsset: false,
  });
  // const [showData, setShowData] = useState([]);
  const [subListingData, setSubListingData] = useState([]);
  // const [assetsData, setassetsData] = useState([]);

  // const [childRowExpansion, setChildRowExpansion] = useState(
  //   Array(subListingData.length).fill(false)
  // );
  // const [rowExpansion, setRowExpansion] = useState(
  //   Array(showData.length).fill(false)
  // );
  const [poster_code_Data, setPoster_code_Data] = useState([]);
  const [uploadAssetsData, setUploadAssetsData] = useState({
    year: "",
    month: "",
    post_order: "",
    post_code: "",
    merged_name: "",
    batch_number: "",
    pre_print_id: "",
    file_name: "",
  });
  const [deleteSubListing, setDeleteSubListing] = useState(false);
  const [uploadAsset, setUploadAsset] = useState(false);
  const [uploadCheck, setUploadCheck] = useState({
    pre_print_mergename_id: "",
    post_order: "",
    merged_name: "",
    batch_number: "",
  });

  const [addQR, setAddQR] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [RWO_Type, setRwoType] = useState({
    hanger: false,
    comp: false,
    RWO: false,
  });
  const [deleteListing, setDeleteListing] = useState(false);

  const [getValues, setValues] = useState({
    month: "",
    year: "",
    printid: "",
    print_order: "",
    batch_number: "",
  });
  const [page, setPage] = useState(1);

  const [deleteSubListing_Data, setDeleteSublisting_Data] = useState({
    pre_print_mergename_id: "",
    post_order: "",
    batch_number: "",
    merged_name: "",
    file_name: "",
  });
  const [childListingData, setChildListingdata] = useState([]);
  const [MenuChecks, setMenuChecks] = useState([]);

  const handleRowToggle = (index, prePrintID) => {
    setRowExpansion((prevRowExpansion) => {
      setChildRowExpansion(Array(subListingData.length).fill(false));
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const handleChildRowToggle = (index, prePrintID, postOrder) => {
    setChildRowExpansion((prevRowExpansion) => {
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      await Get_All_listings(newPage);
    } catch (error) {
      console.log("Error in Pagination", error);
    }
  };

  const handleClick = async (
    option,
    POSTER_CODE_1,
    pre_print_mergename_id,
    post_order,
    file_name,
    month,
    year,
    PO,
    batch_number,
    merged_name
  ) => {
    // console.log(option,post,  'hi I am an option')
    if (option === "Upload Assets") {
      setUploadAssetsData({ ...uploadAssetsData, post_code: POSTER_CODE_1 });
      setUploadAsset(true);
    }
    if (option === "Add QR") {
      setUploadAssetsData({ ...uploadAssetsData, post_code: POSTER_CODE_1 });
      setAddQR(true);
    }
    if (option === "View QR Assets") {
      setUploadAssetsData({ ...uploadAssetsData, post_code: POSTER_CODE_1 });
      navigate("/prePrint/view-assets", {
        state: { uploadAssetsData, post_code: POSTER_CODE_1 },
      });
    }
    if (option === "Merge Printables") {
      // console.log("merge printables");
      const post_code = POSTER_CODE_1;
      await MergePrintables(uploadAssetsData, post_code);
    }
    if (option === "Delete PO") {
      setDeleteSubListing(true);
      setDeleteSublisting_Data({
        batch_number: batch_number,
        post_order: PO,
        pre_print_mergename_id: pre_print_mergename_id,
        merged_name: merged_name,
        file_name: file_name,
      });
    }
    if (option === "View RWO (PO)") {
      navigate(
        `listing/${pre_print_mergename_id}/${post_order}/?${file_name}`,
        { state: { batch_number: batch_number } }
      );
      // dispatch(PrePrintListingFilter({ file_name }));
    }
    if (option === "Upload Comps") {
      setOpen1(true);
      setRwoType({ comp: true });
      setValues({
        month: month,
        year: year.toString(),
        printid: pre_print_mergename_id,
        print_order: post_order,
        batch_number: batch_number,
      });
    }
    if (option === "Upload Hanger") {
      setOpen1(true);
      setRwoType({ hanger: true });
      setValues({
        month: month,
        year: year.toString(),
        printid: pre_print_mergename_id,
        print_order: post_order,
        batch_number: batch_number,
      });
    }
    if (option === "Merge Comps") {
      await MergePrintables_Hanger_Comp(
        PO,
        "comp",
        merged_name,
        pre_print_mergename_id,
        file_name
      );
    }
    if (option === "Merge Hanger") {
      await MergePrintables_Hanger_Comp(
        PO,
        "hanger",
        merged_name,
        pre_print_mergename_id,
        file_name
      );
    }
    if (option === "Export Merged RWO") {
      const post_code = POSTER_CODE_1;
      await final_concat(uploadAssetsData, PO, batch_number);
    }
    if (option === "Final Approve") {
      await Final_approve(PO, batch_number, merged_name, file_name);
    }
    if (option === "Export Merged Comps") {
      await Export_Comps_or_Hangers(
        PO,
        batch_number,
        merged_name,
        file_name,
        "comp"
      );
    }
    if (option === "Export Merged Hanger") {
      await Export_Comps_or_Hangers(
        PO,
        batch_number,
        merged_name,
        file_name,
        "hanger"
      );
    }
  };

  const delete_SubListing = async (
    merge_id,
    post_order,
    merged_name,
    batch_number,
    file_name
  ) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Single_Sub_Listing_Delete, {
        post_order: post_order,
        // pre_print_merge_id: merge_id,
        merged_name: merged_name,
        batch_number,
        file_name,
      });
      if (response.status === 200) {
        // console.log(response.data, "response");
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        await Get_All_listings(page);
        await SubListing(merge_id);
        setDeleteSubListing(false);
        RowClose();
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting API response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response.data.error || err.response.data.message,
        severity: "error",
      });
    }
  };

  const MergePrintables = async (uploadAssetsData, post_code) => {
    const data = {
      post_order: uploadAssetsData.post_order,
      merged_name: uploadAssetsData.merged_name,
      // batch_number: uploadAssetsData.batch_number,
      file_name: uploadAssetsData.file_name,
      POSTER_CODE_1: post_code,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Merge_PO_data, data);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        setSnackbar({
          open: true,
          message: result.data.message,
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error, "Error while Merge Printable API Response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const MergePrintables_Hanger_Comp = async (
    post_order,
    type,
    merged_name,
    pre_print_mergename_id,
    file_name
  ) => {
    const values = {
      pre_print_mergename_id: pre_print_mergename_id,
      post_order: post_order,
      merged_name: merged_name,
      type: type,
      file_name: file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Hanger_Comp_Merge, values);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        Get_All_listings(1);

        setSnackbar({
          open: true,
          message: result.data.message,
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error, "Error while Merge Printable API Response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message:
          error.response.data.message ||
          error.response.data.error ||
          error.response.data,
        severity: "error",
      });
    }
  };

  const final_concat = async (uploadAssetsData, post_code, batch_number) => {
    const data = {
      post_order: post_code,
      merged_name: uploadAssetsData.merged_name,
      batch_number: batch_number,
      // POSTER_CODE_1: post_code,
      file_name: uploadAssetsData.file_name,
    };
    try {
      setIsLoading(true);
      const result = await axios.post(API.Export_Merged_RWO, data, {
        responseType: "blob", // Tell Axios to expect a binary response
      });
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        const fileName = "Assets.zip";
        saveAs(new Blob([result.data]), fileName);
        // saveAs(result.data, fileName); // Directly pass result.data, no need for Blob constructor

        // setSnackbar({
        //   open: true,
        //   message: result.data.message,
        //   severity: "success",
        // });
      }
    } catch (error) {
      console.log(error, "Error while Merge Printable API Response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message:
          error?.response.data?.error ||
          error.response.data.message ||
          error.message,
        severity: "error",
      });
    }
  };

  const Final_approve = async (PO, batch_number, merged_name, file_name) => {
    const values = {
      post_order: PO,
      batch_number: batch_number,
      merged_name: merged_name,
      file_name: file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Pre_Print_Final_Approve, values);
      if (result.status === 200) {
        setIsLoading(false);
        console.log("result", result.data);

        setSnackbar({
          open: true,
          message: result.data.msg,
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error, "Error while Merge Printable API Response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message:
          error.response.data.message ||
          error.response.data.error ||
          error.response.data,
        severity: "error",
      });
    }
  };

  const Export_Comps_or_Hangers = async (
    PO,
    batch_number,
    merged_name,
    file_name,
    type
  ) => {
    const values = {
      post_order: PO,
      batch_number: batch_number,
      merged_name: merged_name,
      file_name: file_name,
      type: type,
    };
    const APIURL =
      `${API.Export_Merge_Hanger_comp_Data}?` +
      `${merged_name ? `merged_name=${merged_name}&` : ""}` +
      `${PO ? `post_order=${PO}&` : ""}` +
      `${batch_number ? `batch_number=${batch_number}&` : ""}` +
      `${type ? `type=${type}` : ""}`;
    try {
      setIsLoading(true);
      // const result = await Axios.post(API.Export_Merge_Hanger_comp_Data, values);
      const result = await Axios.get(APIURL);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        const fileName =
          type === "comp" ? "QRCode_PDF_Comps.csv" : "QRCode_PDF_Hanger.csv";
        saveAs(new Blob([result.data]), fileName);
      }
    } catch (error) {
      console.log(error, "Error while Merge Printable API Response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message:
          error.response.data.message ||
          error.response.data.error ||
          error.response.data,
        severity: "error",
      });
    }
  };

  const SubListing = async (prePrintID) => {
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Sublisting_PrePrint, {
        pre_print_mergename_id: prePrintID,
      });
      if (result.status === 200) {
        setTimeout(() => {
          setSubListingData(result.data.csvData);
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error, "Error while getting API Response");
      setIsLoading(false);
    }
  };

  const addNew = (month, year, printid) => {
    // console.log(month, year, "hello");
    setValues({ month: month, year: year, printid: printid });
    setOpen1(true);
    setRwoType({ RWO: true });
  };

  const ChildListing = async (
    pre_print_mergename_id,
    post_order,
    merged_name,
    batch_number,
    file_name
  ) => {
    const data = {
      pre_print_mergename_id:
        pre_print_mergename_id === undefined
          ? uploadCheck.pre_print_mergename_id
          : pre_print_mergename_id,
      post_order:
        post_order === undefined ? uploadCheck.post_order : post_order,
      merged_name:
        merged_name === undefined ? uploadCheck.merged_name : merged_name,
      batch_number:
        batch_number === undefined ? uploadCheck.batch_number : batch_number,
      file_name:
        batch_number === undefined ? uploadAssetsData.file_name : file_name,
      month: uploadAssetsData?.month,
      year: uploadAssetsData?.year,
      type: 'Data-collection'
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.ChildListing_Preprint, data);
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          setChildListingdata(result.data.data);
        }, 500);
      }
    } catch (error) {
      console.log(error, "Error while getting API Response");
      setIsLoading(false);
    }
  };

  const delete_Listing = async (merge_id, merged_name, file_name) => {
    try {
      const response = await Axios.post(API.Listing_Delete, {
        // pre_print_merge_id: merge_id,
        merged_name: merged_name,
        file_name,
      });
      if (response.status === 200) {
        // console.log(response.data, "response");
        await SubListing(merge_id);
        await Get_All_listings(page);
        setSnackbar({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        setDeleteListing(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error while getting API response");
    }
  };

  const upload_Assets = async(item) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(Auto_Tag_APIs.Get_ICYMI_Links, {
        month:uploadAssetsData?.month,
        year:uploadAssetsData?.year,
        poster_code:item?.POSTER_CODE_1
      });
      if (response.status === 200) {
        setPoster_code_Data(response.data)
        setPopup({
          uploadAsset: true,
        });
        setUploadAssetsData({
          ...uploadAssetsData,
          post_code: item.POSTER_CODE_1,
        });
      setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error while getting API response");
      setIsLoading(false);
      setSnackbar({
        open:true,
        severity:'error',
        message:error?.message
      })
    }
  };

  const handleManageAssets = (item) => {
    setPopup({ ManageAsset: true });
    setUploadAssetsData({
      ...uploadAssetsData,
      post_code: item.POSTER_CODE_1,
    });
  };

  useEffect(() => {
    Get_All_listings(page);
    setChildRowExpansion(Array(subListingData.length).fill(false));
    setRowExpansion(Array(showData.length).fill(false))
  }, []);
  
  return (
    <>
      <TableContainer component={Paper} style={{ border: "1px solid black" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }} className="headings">
                #
              </TableCell>
              <TableCell className="headings">Data Collection</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell
                        style={{ textAlign: "center" }}
                        padding="checkbox"
                      >
                        {/* {serialNumber(page, index)} */}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            handleRowToggle(index, item.pre_print_id);
                            setUploadAssetsData({
                              ...uploadAssetsData,
                              month: item.month,
                              year: item.year,
                              merged_name: item.merged_name,

                              // file_name: item.file_name,
                              // batch_number:item.batch_number
                            });
                          }}
                        >
                          {
                            // listingOpen
                            rowExpansion[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon
                                onClick={async () => {
                                  await SubListing(item.pre_print_id);
                                  // console.log(item.pre_print_mergename_id, 'hello this')
                                  setUploadCheck({
                                    ...uploadCheck,
                                    pre_print_mergename_id: item.pre_print_id,
                                  });
                                }}
                              />
                            )
                          }
                        </IconButton>
                      </TableCell>
                      <TableCell className="common">
                        {item?.merged_name}
                        <br />

                        <Collapse
                          in={rowExpansion[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Details
                            </Typography>
                            <Table
                              size="small"
                              aria-label="purchases"
                              className="dataTable"
                            >
                              <TableHead>
                                <TableRow key={index}>
                                  <TableCell>#</TableCell>
                                  <TableCell>Month/Year</TableCell>
                                  <TableCell>Print order</TableCell>
                                  <TableCell>RWO/Batch Number</TableCell>
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subListingData.length > 0 ? (
                                  subListingData.map((item, index) => (
                                    <>
                                      <TableRow className="child" key={index}>
                                        <TableCell>
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            // onClick={() => setListingOpen(!listingOpen)}
                                            onClick={() =>
                                              handleChildRowToggle(index)
                                            }
                                          >
                                            {
                                              // listingOpen
                                              childRowExpansion[index] ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon
                                                  onClick={async () => {
                                                    // console.log('hello I am in')
                                                    await ChildListing(
                                                      item.pre_print_mergename_id,
                                                      item.post_order,
                                                      item.merged_name,
                                                      item.batch_number,
                                                      item.file_name
                                                    );
                                                    setUploadCheck({
                                                      pre_print_mergename_id:
                                                        item.pre_print_mergename_id,
                                                      post_order:
                                                        item.post_order,
                                                      merged_name:
                                                        item.merged_name,
                                                      batch_number:
                                                        item.batch_number,
                                                    });
                                                    setUploadAssetsData({
                                                      ...uploadAssetsData,
                                                      post_order:
                                                        item.post_order,
                                                      batch_number:
                                                        item.batch_number,
                                                      file_name: item.file_name,
                                                    });
                                                  }}
                                                />
                                              )
                                            }
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>
                                          {item?.merged_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {item?.post_order}
                                        </TableCell>
                                        <TableCell>
                                          {item?.batch_number}
                                        </TableCell>
                                        <TableCell>
                                          <ReusableMenu
                                            APICall="yes"
                                            data={{
                                              state: {
                                                prePrintMergenameId:
                                                  item.pre_print_mergename_id,
                                                post_order: item.post_order,
                                                batch_number: item.batch_number,
                                                merged_name: item.merged_name,
                                                file_name: item.file_name,
                                              },
                                            }}
                                            setMenuChecks={setMenuChecks}
                                            // height="180px"
                                            options={[
                                              "Delete PO",
                                              "View RWO (PO)",
                                              // "View Comps",
                                              // "View Hanger",
                                              // "View Rolled",
                                              // "Export Merged RWO",
                                              // // "Final Approve",
                                              // "Upload Comps",
                                              // "Merge Comps",
                                              // "Export Merged Comps",
                                              // "Upload Hanger",
                                              // "Merge Hanger",
                                              // "Export Merged Hanger",
                                            ]}
                                            // YourSelection={
                                            //   MenuChecks.mergedPOStatus ===
                                            //   false
                                            //     ? [
                                            //         "Export Merged RWO",
                                            //         "Final Approve",
                                            //         "Upload Comps",
                                            //         "Upload Hanger",
                                            //         "Merge Hanger",
                                            //         "Merge Comps",
                                            //         "Export Merged Comps",
                                            //         "Export Merged Hanger",
                                            //       ]
                                            //     : MenuChecks.hangerStatus ===
                                            //         false &&
                                            //       MenuChecks.compsStatus ===
                                            //         false
                                            //     ? [
                                            //         "Merge Hanger",
                                            //         "Merge Comps",
                                            //         "Export Merged Hanger",
                                            //         "Export Merged Comps",
                                            //         // "Export Merged RWO",
                                            //         "Final Approve",
                                            //       ]
                                            //     : MenuChecks.hangerStatus ===
                                            //       false
                                            //     ? [
                                            //         "Merge Hanger",
                                            //         "Export Merged Hanger",

                                            //         // "Export Merged RWO",
                                            //         "Final Approve",
                                            //       ]
                                            //     : MenuChecks.compsStatus ===
                                            //       false
                                            //     ? [
                                            //         "Merge Comps",
                                            //         "Export Merged Comps",
                                            //         // "Export Merged RWO",
                                            //         "Final Approve",
                                            //       ]
                                            //     : MenuChecks.RWOExport === false
                                            //     ? [
                                            //         // "Export Merged RWO",
                                            //         "Final Approve",
                                            //       ]
                                            //     : []
                                            // }
                                            YourSelection={["View Comps",
                                              "View Hanger",
                                              "View Rolled"]}
                                            handleOption={(option) => {
                                              handleClick(
                                                option,
                                                item.POSTER_CODE_1,
                                                item?.pre_print_mergename_id,
                                                item?.post_order,
                                                item.file_name,
                                                item.month,
                                                item.year,
                                                item.post_order,
                                                item.batch_number,
                                                item.merged_name
                                              );
                                            }}
                                          />

                                          <Popup
                                            title={`Are you sure you want to delete the record?`}
                                            open={deleteSubListing}
                                            // overflowY="auto"
                                            // height="365px"
                                            width={"430px"}
                                            content={
                                              <>
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    marginTop: 10,
                                                  }}
                                                >
                                                  <Button
                                                    size="large"
                                                    variant="contained"
                                                    style={{
                                                      background: "#C62828",
                                                      marginRight: "10px",
                                                    }}
                                                    onClick={() =>
                                                      setDeleteSubListing(false)
                                                    }
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    size="large"
                                                    variant="contained"
                                                    style={{
                                                      background: "#1d213e",
                                                      marginRight: "15px",
                                                    }}
                                                    onClick={async () => {
                                                      //   console.log(
                                                      //     deleteSubListing_Data.batch_number
                                                      //   );
                                                      // }
                                                      await delete_SubListing(
                                                        deleteSubListing_Data.pre_print_mergename_id,
                                                        deleteSubListing_Data.post_order,
                                                        deleteSubListing_Data.merged_name,
                                                        deleteSubListing_Data.batch_number,
                                                        deleteSubListing_Data.file_name
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                  </Button>
                                                </div>
                                              </>
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          style={{ borderBottom: 0 }}
                                          colSpan={5}
                                        >
                                          <div className="subchild">
                                            <Collapse
                                              in={childRowExpansion[index]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box sx={{ margin: 1 }}>
                                                <Typography
                                                  variant="h6"
                                                  gutterBottom
                                                  component="div"
                                                >
                                                  Sub Details
                                                </Typography>
                                                <div
                                                  size="small"
                                                  aria-label="purchases"
                                                >
                                                  <Table className="dataTable">
                                                    <TableHead>
                                                      <TableRow key={index}>
                                                        <TableCell>
                                                          Poster code
                                                        </TableCell>
                                                        <TableCell>
                                                          Status
                                                        </TableCell>
                                                        <TableCell
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Actions
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {childListingData.length >
                                                      0 ? (
                                                        childListingData.map(
                                                          (item, index) => (
                                                            <TableRow
                                                              key={index}
                                                            >
                                                              <TableCell>
                                                                {
                                                                  replaceSymbols(item.POSTER_CODE_1)
                                                                }
                                                              </TableCell>
                                                              <TableCell>
                                                                {item.assets_status ===
                                                                false ? (
                                                                  <p className="pending">
                                                                    Pending
                                                                  </p>
                                                                ) : (
                                                                  <p className="approve">
                                                                    Uploaded
                                                                  </p>
                                                                )}
                                                              </TableCell>

                                                              <TableCell>
                                                                <div className="AlignItems">
                                                                  {item.assets_status ===
                                                                  false ? (
                                                                    <Button
                                                                      size="large"
                                                                      variant="contained"
                                                                      style={{
                                                                        background:
                                                                          "#15223F",
                                                                        marginBottom: 10,
                                                                      }}
                                                                      startIcon={<FaUpload style={{fontSize:17}} />}
                                                                      onClick={() =>
                                                                        upload_Assets(
                                                                          item
                                                                        )
                                                                      }
                                                                    >
                                                                      Upload
                                                                      Asset
                                                                    </Button>
                                                                  ) : (
                                                                    <Button
                                                                      size="large"
                                                                      variant="contained"
                                                                      style={{
                                                                        background:
                                                                          "#15223F",
                                                                        marginBottom: 10,
                                                                        padding: '8px 40px 8px 40px'
                                                                      }}
                                                                      startIcon={<RiFolderSettingsFill style={{fontSize:18}} />}
                                                                      onClick={() =>
                                                                        handleManageAssets(
                                                                          item
                                                                        )
                                                                      }
                                                                    >
                                                                      Manage
                                                                    </Button>
                                                                  )}
                                                                </div>
                                                              </TableCell>
                                                              {/* <TableCell
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <ReusableMenu
                                                                  APICall="no"
                                                                  options={[
                                                                    "Upload Assets",
                                                                    item.assets_status ===
                                                                    true
                                                                      ? "Add QR"
                                                                      : null,
                                                                    item.qr_status ===
                                                                    true
                                                                      ? "View QR Assets"
                                                                      : null,
                                                                    item.assets_status ===
                                                                      true &&
                                                                    item.qr_status ===
                                                                      true
                                                                      ? "Merge Printables"
                                                                      : null,
                                                                  ].filter(
                                                                    (option) =>
                                                                      option !==
                                                                      null
                                                                  )}
                                                                  YourSelection={
                                                                    item.final_qr_status ===
                                                                    true
                                                                      ? [
                                                                          "Add QR",
                                                                        ]
                                                                      : []
                                                                  }
                                                                  handleOption={(
                                                                    option
                                                                  ) => {
                                                                    handleClick(
                                                                      option,
                                                                      item.POSTER_CODE_1
                                                                    );
                                                                  }}
                                                                />
                                                              </TableCell> */}
                                                            </TableRow>
                                                          )
                                                        )
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            colSpan={3}
                                                          >
                                                            Record Not Found
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </div>
                                              </Box>
                                            </Collapse>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      colSpan={5}
                                    >
                                      Record Not Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={2}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showData?.length > 0 && next?.totalPages > 1 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        />
      )}
      {/* <Popup
        title={
          assetsData.length === 0
            ? "Please Upload Assets Here"
            : "Edit Assets here"
        }
        open={uploadAsset}
        overflowY="auto"
        height="480px"
        content={
          <>
            <UploadAssets
              close={() => setUploadAsset(false)}
              setSnackbar={setSnackbar}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              uploadAssetsData={uploadAssetsData}
              setassetsData={setassetsData}
              ChildListing={ChildListing}
              SubListing={SubListing}
              uploadCheck={uploadCheck}
            />
          </>
        }
      /> */}
      <Popup
        title="Choose the manual/ AD/ ICYMI files to upload"
        open={popup.uploadAsset}
        overflowY="auto"
        height="450px"
        content={
          <>
            <Upload_Assets
              close={() => setPopup({ uploadAsset: false })}
              setSnackbar={setSnackbar}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              uploadAssetsData={uploadAssetsData}
              Axios={Axios}
              API={API}
              ChildListing={ChildListing}
              poster_code_Data={poster_code_Data}
            />
          </>
        }
      />

      <Popup
        open={popup.ManageAsset}
        overflowY="auto"
        height="505px"
        // width="400px"
        padding="0"
        //  CloseHeight="490px"
        // CloseOverflow="auto"
        // closePopup={() => setPopup({ ManageAsset: false })}
        content={
          <>
            <Manage_Assets
              close={() => setPopup({ ManageAsset: false })}
              setSnackbar={setSnackbar}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              Axios={Axios}
              API={API}
              uploadAssetsData={uploadAssetsData}
              ChildListing={ChildListing}
              RowClose={RowClose}
              poster_code_Data={poster_code_Data}
            />
          </>
        }
      />
    </>
  );
};

export default ListingData;
