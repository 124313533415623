import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FaFileCsv, FaFilePdf, FaUpload } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';
import '../style.css';
import { MdDelete } from 'react-icons/md';
import { Auto_Tag_APIs, Meta_DataAPIS } from 'api/API';
import { replaceSymbols } from 'functions/Functions';

const Manage_Assets = ({
  isLoading,
  setIsLoading,
  setSnackbar,
  close,
  uploadAssetsData,
  Axios,
  API,
  RowClose,
  ChildListing,
  poster_code_Data,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [ListingData, setListingData] = useState([]);

  const fileFields = [
    { name: 'manual', label: 'Manual Assets' },
    { name: 'ad', label: 'Ad Assets' },
    { name: 'icymi', label: 'ICYMI Assets' },
  ];

  const validationSchema = Yup.object().shape({
    manual: Yup.mixed().required('Manual file is required'),
    ad: Yup.mixed().required('Ad file is required'),
  });

  const AllValues = {
    manual: '',
    ad: '',
    icymi: '',
  };

  const FileChangeHandler = (fieldName, setFieldValue) => event => {
    let files = event.target.files;

    // Initialize a flag to check the uploaded file type matches the field
    let validFileForField = false;

    // Loop through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && file.name.toLowerCase().endsWith('.pdf')) {
        let poster_code = replaceSymbols(uploadAssetsData.post_code);
        let fileName = `${poster_code}_${uploadAssetsData.month
          .slice(0, 3)
          .toUpperCase()}${uploadAssetsData.year.slice(2, 4)}`;

        // Check if file name contains the poster_code and the expected type
        if (file.name.includes(fileName) && file.name.toLowerCase().includes(fieldName)) {
          validFileForField = true;
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            message: `File name not matched for ${fieldName.toUpperCase()}. Please upload the correct ${fieldName} file.`,
          });
          event.target.value = '';
          return; // Exit if any file name doesn't match the expected pattern
        }
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please select PDF files only',
        });
        event.target.value = '';
        setFieldValue(fieldName, []);
        return; // Exit if any file is not a PDF
      }
    }

    // Check if the uploaded files match the field type
    if (validFileForField) {
      setFieldValue(fieldName, files); // Only set files if they match the field type
    } else {
      setSnackbar({
        open: true,
        severity: 'error',
        message: `Please upload the required ${fieldName} file(s).`,
      });
      event.target.value = ''; // Clear input if file type does not match
    }
  };

  const handleSubmit = async values => {
    const allEmpty = Object.values(values).every(value => value === '');
    const formData = new FormData();
    const fileTypes = ['manual', 'ad', 'icymi'];

    // Helper function to capitalize the first letter of a string
    const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    // Loop through each file type to convert and append to formData
    fileTypes.forEach(type => {
      Array.from(values[type]).forEach(file => {
        formData.append(capitalizeFirstLetter(type), file);
      });
    });
    const data = {
      month: uploadAssetsData.month,
      year: uploadAssetsData.year,
      post_order: uploadAssetsData.post_order,
      merged_name: uploadAssetsData.merged_name,
      post_code: uploadAssetsData.post_code,
      batch_number: uploadAssetsData.batch_number,
      file_name: uploadAssetsData.file_name,
      ICYMI_Files: poster_code_Data.data,
    };

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    try {
      if (allEmpty) {
        setSnackbar({
          open: true,
          message: 'Please add atleast one asset first',
          severity: 'warning',
        });
      } else {
        setIsLoading(true);
        const result = await Axios.Filepost(Auto_Tag_APIs.Edit_Assets, formData);
        if (result.status === 200) {
          // console.log(result.data, "hello I am in");
          ChildListing();
          close();
          setSnackbar({ open: true, message: result.data.msg, severity: 'success' });
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response.data.error || error.message,
      });
    }
  };

  const getFileNameFromUrl = url => {
    if (typeof url !== 'string') {
      // Return null or an empty string if the URL is not a valid string
      return null;
    }

    // Split the URL by '/' and get the last part
    const urlParts = url.split('/');
    // Get the last part which is the file name with parameters
    const lastPart = urlParts[urlParts.length - 1];
    // Split the last part by '?' to remove query parameters
    const fileName = lastPart.split('?')[0];

    // Decode the file name to replace %20 with spaces and handle other encoded characters
    return decodeURIComponent(fileName);
  };

  const getData = async () => {
    const data = {
      post_code: uploadAssetsData.post_code,
      file_name: uploadAssetsData.file_name,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Meta_data, data);
      if (response.status === 200) {
        setListingData(response.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting Listing Data');
      setListingData([]);
      setIsLoading(false);
    }
  };

  const Delete_All_Assets = async () => {
    const data = {
      post_code: uploadAssetsData.post_code,
      file_name: uploadAssetsData.file_name,
      post_order: uploadAssetsData.post_order,
      batch_number: uploadAssetsData.batch_number,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Delete_ICYMI_Assets, data);
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: 'success',
        });
        await getData();
        await ChildListing();
        // RowClose();
        close();
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting Categories');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.message,
        severity: 'error',
      });
    }
  };

  const Delete_Specific_File = async (FileType, URL, Filename) => {
    const cleanedURL = URL.split('?')[0];
    const data = {
      post_code: uploadAssetsData.post_code,
      file_name: uploadAssetsData.file_name,
      [FileType]: FileType === 'ICYMI' || FileType === 'ad' ? [cleanedURL] : cleanedURL,
    };

    try {
      setIsLoading(true);
      const response = await Axios.post(Meta_DataAPIS.Delete_Specific_Files, data);

      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: 'success',
        });
        await getData();
        await ChildListing();
      }
    } catch (err) {
      console.log(err, 'Error while deleting files');
      setSnackbar({
        open: true,
        message: err.message,
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const FileTable = ({ title, files, FileType }) => (
    <>
      {/* <strong>{title} Files</strong> */}
      <br />
      <div>
        <table className="accordion">
          <tr>
            <th>{title} Files</th>
            <th style={{ textAlign: 'center', color: '#c62828' }}>{/* Delete All Files */}</th>
          </tr>
          {files?.map((file, index) => (
            <tr key={index}>
              <td style={{ width: '100%' }}>
                <a href={file} style={{ color: '#0179d9' }} target="_blank">
                  {getFileNameFromUrl(file)}
                </a>
              </td>
              {FileType === 'ICYMI' && (
                <td style={{ textAlign: 'center' }}>
                  <MdDelete
                    onClick={() => Delete_Specific_File(FileType, file, getFileNameFromUrl(file))}
                    style={{ color: '#c62828', cursor: 'pointer', fontSize: 18 }}
                  />
                </td>
              )}
            </tr>
          ))}
        </table>
      </div>
    </>
  );

  const Manual = ListingData?.every(item => item?.manual === undefined);
  const Ad = ListingData[0]?.ad.every(item => item === undefined);
  const ICYMI = ListingData[0]?.ICYMI.every(item => item === undefined);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ padding: '16px' }}>
      <Typography id="modal-modal-title" variant="h3" component="div" sx={{ marginBottom: 2 }}>
        Choose the manual/ AD/ ICYMI files to upload
      </Typography>
      <Formik
        initialValues={AllValues}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others} autoComplete="off">
            <Grid
              container
              spacing={matchDownSM ? 0 : 2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {/* Files */}
              {fileFields.map(field => (
                <Grid item xs={12} key={field.name}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched[field.name] && errors[field.name])}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor={`outlined-adornment-${field.name}`}>
                      {field.label}
                    </InputLabel>
                    <Input
                      id={`outlined-adornment-${field.name}`}
                      type="file"
                      name={field.name}
                      inputProps={{
                        accept: '.pdf',
                        multiple: true, // Allow multiple file selection
                      }}
                      onBlur={handleBlur}
                      onChange={FileChangeHandler(field.name, setFieldValue)}
                      endAdornment={
                        <InputAdornment sx={{ fontSize: 22, marginTop: 2 }} position="end">
                          <FaFilePdf />
                        </InputAdornment>
                      }
                    />
                    {touched[field.name] && errors[field.name] && (
                      <FormHelperText error id={`standard-weight-helper-text-${field.name}`}>
                        {errors[field.name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            {/* {poster_code_Data?.data?.length < 1 ? null : (
              <FileTable title="ICYMI" files={poster_code_Data?.data} FileType="ICYMI" />
            )} */}

            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: '#C62828',
                  marginRight: '10px',
                  color: isLoading === true && '#fff',
                }}
                onClick={Delete_All_Assets}
                disabled={isLoading === true ?? true}
                startIcon={<MdDelete style={{ fontSize: 18 }} />}
              >
                Delete All files
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: '#1d213e',
                  marginRight: '15px',
                  color: isLoading === true && '#fff',
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>

            <Divider style={{ marginTop: 10 }} />
            {Manual ? null : (
              <FileTable
                title="Manual"
                files={ListingData?.map(item => item?.manual)}
                FileType="manual"
              />
            )}
            {Ad ? null : <FileTable title="Ad" files={ListingData[0]?.ad} FileType="ad" />}
            {ICYMI ? null : (
              <FileTable title="ICYMI" files={ListingData[0]?.ICYMI} FileType="ICYMI" />
            )}
          </form>
        )}
      </Formik>
      <div style={{ textAlign: 'center', marginTop: 8 }}>
        <Button
          size="large"
          variant="contained"
          style={{
            background: '#C62828',
            color: isLoading === true && '#fff',
          }}
          onClick={close}
          disabled={isLoading === true ?? true}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default Manage_Assets;
