const STAGING = "preview"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "zwzasj9gdq7nx31j6ba2apb0hu9ujd2s80pi4rzn83gb1taq",
  dev: "vimadnpop74f3zlrv2tmxv2ihh3wblsg6s7pzqc6hpndedjh",
  prod: "omkjpeeljai4s7i4zimgk24v82bud1wslchehtgqgc8kjate",
  preview: "gio19ur08p0cscvhva47sqy3a284tys8kxduggpvlx42nncu",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}